// i18n
import './locales/i18n'

// highlight
import './utils/highlight'

// scroll bar
import 'simplebar/src/simplebar.css'

// lightbox
import 'react-image-lightbox/style.css'

// map
import 'mapbox-gl/dist/mapbox-gl.css'

// editor
import 'react-quill/dist/quill.snow.css'

// slick-carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import 'moment/locale/es'

import * as Sentry from "@sentry/react";
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
// import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { SnackbarProvider } from 'notistack'
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
// redux
import { QueryClientProvider } from '@tanstack/react-query'
import { store, persistor } from './redux/store'
// contexts
import { SettingsProvider } from './contexts/SettingsContext'
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext'
import { SocketProvider } from './contexts/PusherContext'


// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AuthProvider } from './contexts/JWTContext'

//
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import queryClient from './query-client'
// import { FallbackUI } from "./sentry-config";


// ----------------------------------------------------------------------

// const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Sentry.ErrorBoundary>
    {/* <HelmetProvider context={helmetContext}> */}
      <SnackbarProvider>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <ReduxProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <SocketProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SettingsProvider>
                      <CollapseDrawerProvider>
                        <BrowserRouter>
                          <App />
                        </BrowserRouter>
                      </CollapseDrawerProvider>
                    </SettingsProvider>
                  </LocalizationProvider>
                </SocketProvider>
              </PersistGate>
            </ReduxProvider>
          </QueryClientProvider>
        </AuthProvider>
      </SnackbarProvider>
    {/* </HelmetProvider> */}
  </Sentry.ErrorBoundary>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
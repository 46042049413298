// ----------------------------------------------------------------------

function path (root, sublink) {
    return `${root}${sublink}`
  }
  
  const ROOTS_AUTH = '/auth'
  const ROOTS_DASHBOARD = '/dashboard'
  
  // ----------------------------------------------------------------------
  
  export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/register'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    verify: path(ROOTS_AUTH, '/verify'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    newPassword: path(ROOTS_AUTH, '/new-password'),
    forgotPassword: path(ROOTS_AUTH, '/forgot-password')
  }
  
  export const PATH_PAGE = {
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    page403: '/403',
    page404: '/404',
    page500: '/500',
    components: '/components'
  }
  
  export const PATH_DASHBOARD = {
    root: `${ROOTS_DASHBOARD}/acreditados/home`,
    acreditados: {
      home: path(ROOTS_DASHBOARD, '/acreditados/home'),
      approvals: path(ROOTS_DASHBOARD, '/acreditados/aprobaciones'),
      clients: path(ROOTS_DASHBOARD, '/acreditados/clientes'),
      requests: path(ROOTS_DASHBOARD, '/acreditados/solicitudes'),
      loans: path(ROOTS_DASHBOARD, '/acreditados/prestamos'),
      nips: path(ROOTS_DASHBOARD, '/acreditados/nips'),
      reports: {
        root: path(ROOTS_DASHBOARD, '/acreditados/reportes'),
        requests: path(ROOTS_DASHBOARD, '/acreditados/reportes/solicitudes'),
        goToCredit: path(ROOTS_DASHBOARD, '/acreditados/reportes/pases-a-credito'),
        tickets: path(ROOTS_DASHBOARD, '/acreditados/reportes/aprobaciones'),
        nips: path(ROOTS_DASHBOARD, '/acreditados/reportes/nips'),
        credit: path(ROOTS_DASHBOARD, '/acreditados/reportes/circulo'),
      },
      debtColletion: {
        root: path(ROOTS_DASHBOARD, '/acreditados/cobranza'),
        newCampaign: path(ROOTS_DASHBOARD, '/acreditados/cobranza/nueva-campaña'),
        campaigns: {
          root: path(ROOTS_DASHBOARD, '/acreditados/cobranza/campañas'),
          detail: (id) => path(ROOTS_DASHBOARD, `/acreditados/cobranza/campañas/${id}`),
        }
      },
      history: path(ROOTS_DASHBOARD, '/history'),
      historyScore: path(ROOTS_DASHBOARD, '/historial-crediticio'),
      applications: path(ROOTS_DASHBOARD, '/acreditados/solicitudes'),
    },
    user: {
      root: path(ROOTS_DASHBOARD, '/user'),
      account: path(ROOTS_DASHBOARD, '/user/account'),
      edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
      demoEdit: path(ROOTS_DASHBOARD, '/user/reece-chung/edit')
    },
    stores: {
      root: path(ROOTS_DASHBOARD, '/tiendas/home'),
      users: path(ROOTS_DASHBOARD, '/tiendas/usuarios') 
    },
    conversations: {
      root: path(ROOTS_DASHBOARD, '/conversaciones'),
      detail: (id) => path(ROOTS_DASHBOARD, `/conversaciones/${id}`),
    },
  }
  
  export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction'